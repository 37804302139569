.status_active {
    border-radius: 5px;
    background: rgb(182 51 46 / 78%);
    color: aliceblue;
    padding: 5px;
    border: none;
    text-align: center;
}
.add_button{
    
    font-size: 0.7rem;
    font-weight: 600;
    background: rgb(182, 51, 46);
    width: min-content;
    margin-bottom: 20px;
    border-radius: 5px;
    
}
.driver_photo {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
}
.center_photo{
    display: flex;
    justify-content: center;
}
