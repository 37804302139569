.chart_wrapper {
    background: white;
    padding: 20px;
    border-radius: 25px;
    border: 1px solid #0000001f;
    box-shadow: 0 0 20px 10px #e7e7e7;
    margin: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chart-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: 2fr 1fr; 
    gap: 20px; 
}
.text_dashboard{
    position: absolute;
    text-align: center;
    display: flex;
    top: 20px;
    justify-content: center;
    align-items: center;
    width: 95%;
    font-weight: 600;
    font-size: 1.5rem;
}

.segment_select {
    width: 20%;
    z-index: 1000;
    position: absolute;
    top: -10%;
    left: 20%;
}
.rdrDateRangePickerWrapper{
    padding: 12px;
    background: #e9e9e9;
    border-radius: 10px;
}
.date_selector_button {
    position: relative;
    z-index: 1000;
    background: rgb(182, 51, 46);
    border: none;
    font-weight: 500;
    color: white;
}

.expenses_table {
    min-width: 300px;
    margin-top: 25px;
}

.date-select {
    color: #969696;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    top: 10px;
    right: 10px;
}
.recharts-layer {
  outline: none !important;
}
.noInfo {
    text-align: center;
    width: 100%;
    font-size: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.noInfo img{
    width: 70px;
}